import React from "react";
import BaseLayout from "../components/baseLayout";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage, getImage } from "gatsby-plugin-image";
import "../style/index.css";
import HeaderHelmet from "../components/headerHelmet";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage, {
  IBackgroundImageProps,
} from "gatsby-background-image";
import { Partner } from "../components/partner";

type imgProp = {
  img: Partial<IBackgroundImageProps | IBackgroundImageProps[]>;
  text: string;
  credits: string | null;
};

export default function IndexPage() {
  const data = useStaticQuery(graphql`
    query {
      TRIM_brochure_DRR: file(name: { eq: "TRIM_brochure_DRR" }) {
        publicURL
      }
      flyer_safe: file(name: { eq: "flyer_safe" }) {
        publicURL
      }
      One_Health_for_HEAL_STELLA_MARIS_GLOBAL_FLYER: file(
        name: { eq: "One_Health_for_HEAL_STELLA_MARIS_GLOBAL_FLYER" }
      ) {
        publicURL
      }
      imgOpenGraph: file(relativePath: { eq: "index/ElenaAfrica.png" }) {
        childImageSharp {
          openGraphImg: gatsbyImageData(
            width: 1200
            height: 630
            formats: [JPG]
          )
        }
      }
      img0: file(relativePath: { eq: "index/ElenaAfrica.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, quality: 70)
        }
      }
      img1: file(relativePath: { eq: "index/VelaJozo.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, quality: 70)
        }
      }
      img2: file(relativePath: { eq: "index/Camels.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, quality: 70)
        }
      }
      img3: file(relativePath: { eq: "index/ElenaBoat.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, quality: 70)
        }
      }
      img4: file(relativePath: { eq: "index/rainGauge.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, quality: 70)
        }
      }
      img5: file(relativePath: { eq: "index/vela.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, quality: 70)
        }
      }
      img6: file(relativePath: { eq: "index/dataCollection.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, quality: 70)
        }
      }
      img7: file(relativePath: { eq: "index/rio.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, quality: 70)
        }
      }
      img8: file(relativePath: { eq: "index/Charts.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, quality: 70)
        }
      }
      img9: file(relativePath: { eq: "index/desert.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, quality: 70)
        }
      }
      img10: file(relativePath: { eq: "index/Cows.JPG" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, quality: 70)
        }
      }
    }
  `);

  const pList: imgProp[] = [
    {
      img: convertToBgImage(getImage(data.img0)),
      text: "We Transform Data into Actions",
      credits: null,
    },
    {
      img: convertToBgImage(getImage(data.img1)),
      text: "Data Collection and Recording",
      credits: null,
    },
    {
      img: convertToBgImage(getImage(data.img2)),
      text: "Environmental Analysis",
      credits: null,
    },
    {
      img: convertToBgImage(getImage(data.img3)),
      text: "Data Analysis and Visualization",
      credits: null,
    },
    {
      img: convertToBgImage(getImage(data.img4)),
      text: "Weather Monitoring",
      credits: null,
    },
    {
      img: convertToBgImage(getImage(data.img5)),
      text: "Weather Coaching",
      credits: "Photo by Luca Bursic",
    },
    {
      img: convertToBgImage(getImage(data.img6)),
      text: "Capacity building and community-based approaches",
      credits: null,
    },
    {
      img: convertToBgImage(getImage(data.img7)),
      text: "Operational Weather Forecasting",
      credits: null,
    },
    {
      img: convertToBgImage(getImage(data.img8)),
      text: "Participatory Interpretation of Data",
      credits: null,
    },
    {
      img: convertToBgImage(getImage(data.img9)),
      text: "Climate Analysis and Disaster Risk Reduction",
      credits: null,
    },
    {
      img: convertToBgImage(getImage(data.img10)),
      text: "Diseases Surveillance",
      credits: null,
    },
  ];

  return (
    <BaseLayout>
      <HeaderHelmet
        url="https://trimweb.it"
        title="TriM"
        description="Translate into Meaning"
        image={
          new URL(
            data.imgOpenGraph.childImageSharp.openGraphImg.images.fallback.src,
            "https://trimweb.it"
          )
        }
      />

      {pList.map((p, index) => (
        <Img key={index} {...p} />
      ))}

      <div className="wrapper">
        <div className="downloadGroup">
          <div className="download">
            <div className="section-subtitle">Disaster Risk Reduction </div>
            <a
              className="button"
              href={data.TRIM_brochure_DRR.publicURL}
              download
            >
              Download PDF
            </a>
          </div>

          <div className="download">
            <div className="section-subtitle">
              One Health Information System
            </div>
            <a
              className="button"
              href={
                data.One_Health_for_HEAL_STELLA_MARIS_GLOBAL_FLYER.publicURL
              }
              download
            >
              Download PDF
            </a>
          </div>

          <div className="download">
            <div className="section-subtitle">
              Child Protection Service Providers Management System
            </div>
            <a className="button" href={data.flyer_safe.publicURL} download>
              Download PDF
            </a>
          </div>
        </div>

        <div className="wrapper">
          <SDGs />
        </div>

      <PartnerLogos/>

      </div>
    </BaseLayout>
  );
}

function Img(p: imgProp) {
  return (
    <BackgroundImage {...p.img} loading="eager" preserveStackingContext>
      <div className="img-container">
        <div className="title-container container">
          <h1 className="my-title">{p.text}</h1>
        </div>
        {p.credits && (
          <div className="photo-credits has-text-weight-bold is-size-7">
            {p.credits}
          </div>
        )}
      </div>
    </BackgroundImage>
  );
}

function SDGs() {
  return (
    <>
      <div className="section-subtitle">
        We support the Sustainable Development Goals
      </div>

      <div className="SGD-icon-container">
        <StaticImage
          alt="sdg2"
          layout="fixed"
          width={100}
          aspectRatio={1}
          className="SGD-icon"
          src="../../images/index/sdg2.png"
        />

        <StaticImage
          alt="sdg3"
          layout="fixed"
          width={100}
          aspectRatio={1}
          className="SGD-icon"
          src="../../images/index/sdg3.png"
        />

        <StaticImage
          alt="sdg13"
          layout="fixed"
          width={100}
          aspectRatio={1}
          className="SGD-icon"
          src="../../images/index/sdg13.png"
        />
      </div>
    </>
  );
}


function PartnerLogos() {
  const partners = ['CISP', 'AMREF', 'ActionAid', 'TDH', 'VSF-G'];
    return (
          <div className="partner-container">
            <div className="section-subtitle">Our Partners</div>

            <div className="partner-list">
              {partners.map((partnerTag, index) => (
                  <Partner partnerTag={partnerTag} key={index}/>
              ))}
            </div>
          </div>
    );
}