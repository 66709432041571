import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import React from "react";

export function Partner({ partnerTag }) {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//content/partners//" } }
      ) {
        edges {
          node {
            frontmatter {
              tag
              img {
                childImageSharp {
                  gatsbyImageData(height: 180)
                }
              }
            }
          }
        }
      }
    }
  `);
  const partner = data.allMarkdownRemark.edges.find(
    (x) => x.node.frontmatter.tag === partnerTag
  );
  return !!partner ? (
    <div className="shadow round fixedWidth responsiveMargin partner-container">
      <GatsbyImage
        className="responsiveMargin round partner"
        image={partner.node.frontmatter.img.childImageSharp.gatsbyImageData}
        alt={partnerTag}
        objectFit={"contain"}
      />
    </div>
  ) : (
    <div className="shadow round responsiveMargin fixedWidth">
      ERROR: {partnerTag}
    </div>
  );
}
